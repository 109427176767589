<template>
  <div class="flex">
    <div class="py-8 pr-7 w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Services</h1>
        <template v-if="roles.includes('Inventory Manager')">
          <button
            class="bg-primary py-4 px-5 w-48 text-white rounded-xl shadow-md font-bold flex items-center gap-5"
            @click="newProductModal = true"
          >
            <plus />
            New Service
          </button>
        </template>
      </div>
      <div class="" v-if="items.length !== 0">
        <product-table
          :allItems="items"
          :perPage="perPage"
          :total="total"
          :currentPage="currentPage"
          @edit="editProduct"
          @delete="deleteProduct"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <add-new-product-modal
      :product="product"
      :edit="edit"
      v-if="newProductModal"
      @close="newProductModal = !newProductModal"
      @created="fetchProduct"
    />
  </div>
</template>

<script>
import { fetchProducts, deleteService } from "@/services/inventory";

export default {
  name: "Products",
  components: {
    ProductTable: () => import("@/components/Inventory/ProductTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
    AddNewProductModal: () =>
      import("@/components/Inventory/AddNewProductModal.vue"),
    plus: () => import("@/assets/icons/PlusIcon.vue"),
  },
  computed: {
    roles() {
      return this.$store.state.roles;
    },
  },
  data() {
    return {
      items: [],
      perPage: 0,
      currentPage: 0,
      total: 0,
      newProductModal: false,
      edit: false,
      product: {
        service_name: "",
        service_amount: "",
        service_description: "",
      },
    };
  },
  methods: {
    async fetchProduct(page = 1) {
      this.newProductModal = false;
      this.product = {
        service_name: "",
        service_amount: "",
        service_description: "",
      };
      try {
        const products = await fetchProducts(page);
        this.items = products.data.inventory.data;
        this.currentPage = products.data.inventory.current_page;
        this.perPage = products.data.inventory.per_page;
        this.total = products.data.inventory.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    editProduct(item) {
      this.edit = true;
      this.product = item;
      this.newProductModal = true;
    },
    async deleteProduct(id) {
      try {
        await deleteService(id);
        this.$toast.success("Service deleted");
        this.fetchProduct();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
  },
  mounted() {
    this.fetchProduct();
  },
};
</script>
