import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function createItem(payload) {
  return axios.post(
    `${baseURL}/inventory-item`,
    { ...payload },
    { headers: header }
  );
}

export function getItem() {
  return axios.get(`${baseURL}/inventory-type?page_number=100`, {
    headers: header,
  });
}

export function createType(payload) {
  return axios.post(
    `${baseURL}/inventory-type`,
    { ...payload },
    { headers: header }
  );
}

export function createInventory(payload) {
  return axios.post(
    `${baseURL}/inventory`,
    { ...payload },
    { headers: header }
  );
}

export function fetchInventories(pageNumber = 1) {
  return axios.get(`${baseURL}/inventory?page=${pageNumber}`, {
    headers: header,
  });
}

export function createProduct(payload) {
  return axios.post(
    `${baseURL}/inventoryservice`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function fetchProducts(pageNumber = 1) {
  return axios.get(`${baseURL}/inventoryservice?page=${pageNumber}`, {
    headers: header,
  });
}

export function fetchAllItems() {
  return axios.get(`${baseURL}/inventory-item/all`, { headers: header });
}

export function fetchAnItem(product_id) {
  return axios.get(
    `${baseURL}/inventory-item/all/inventory?inventory_item_id=${product_id}`,
    { headers: header }
  );
}

export function fetchAllServices() {
  return axios.get(`${baseURL}/inventoryservice/get/list`, { headers: header });
}
export function deleteService(id) {
  return axios.delete(`${baseURL}/inventoryservice/${id}`, { headers: header });
}

export function editService(id, payload) {
  return axios.put(
    `${baseURL}/inventoryservice/${id}`,
    { ...payload },
    { headers: header }
  );
}

export function deleteInventoryItem(id) {
  return axios.delete(`${baseURL}/inventory/${id}`, { headers: header });
}

export function editInventory(id, payload) {
  return axios.put(
    `${baseURL}/inventory/${id}`,
    { ...payload },
    { headers: header }
  );
}
